import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import { Locale } from 'vant'
import messages from './index'
const lang = localStorage.getItem('lang') || 'es-es'
import zhCN from 'vant/es/locale/lang/zh-CN';
import deDE from 'vant/lib/locale/lang/de-DE'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import eses from 'vant/lib/locale/lang/en-US'
import itit from 'vant/lib/locale/lang/it-IT'
import vivi from 'vant/lib/locale/lang/vi-VN'
import ukuk from 'vant/lib/locale/lang/uk-UA'
import trtr from 'vant/lib/locale/lang/tr-TR'

const i18n = createI18n({
  fallbackLocale: 'es-es',
  globalInjection:true,
  legacy: false, // you must specify 'legacy: false' option
  locale: lang,
  messages,
});
  // 更新vant组件库本身的语言变化，支持国际化
  function vantLocales (language) {
    if (language === 'hy-hy') {
      Locale.use(language, zhCN)
    } else if (language === 'de-de') {
      Locale.use(language, deDE)
    }else if(language === 'jp-jp'){
      Locale.use(language, jaJP)
    }else if(language === 'es-es'){
      Locale.use(language, eses)
    }else if(language === 'it-it'){
      Locale.use(language, itit)
    }
	else if(language === 'uk-uk'){
	  Locale.use(language, ukuk)
	}
	else if(language === 'vi-vi'){
	  Locale.use(language, vivi)
	}
	else if(language === 'tr-tr'){
	  Locale.use(language, trtr)
	}
  }

export {i18n,vantLocales}